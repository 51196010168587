<template>
	<v-video
		v-if="video"
		ref="videoEl"
		:height="video.height"
		:src="video.url"
		:width="video.width" />
</template>

<script lang="ts" setup>
	import { isArray } from 'lodash-es'
	import type { VideoAsset } from '@/graphql/video'
	import type { MaybeArray } from '@/types/maybe-array'
	import Video, { type VideoOptions } from '~/components/common/video.vue'

	const props = defineProps<{
		options?: CraftVideoOptions
		value?: MaybeArray<VideoAsset>
	}>()

	const videoEl = ref<InstanceType<typeof Video>>()

	const video = computed(() =>
		isArray(props.value) ? props.value[0] : props.value,
	)

	defineExpose({
		play: () => videoEl.value?.play(),
		pause: () => videoEl.value?.pause(),
		seekTo: (time: number) => videoEl.value?.seekTo(time),
		toggle: () => videoEl.value?.toggle(),
	})
</script>

<script lang="ts">
	export type CraftVideoOptions = Omit<
		VideoOptions,
		'alt' | 'height' | 'src' | 'width'
	>
</script>

<style lang="scss"></style>

<template>
	<v-craft-embed
		v-if="type === 'embed'"
		ref="embedEl"
		:value="value as EmbedAsset"
		v-bind="options?.embed" />

	<v-craft-image
		v-else-if="type === 'image'"
		ref="imageEl"
		:value="value as ImageAsset"
		v-bind="options?.image" />

	<v-craft-video
		v-else-if="type === 'video'"
		ref="videoEl"
		:value="value as VideoAsset"
		v-bind="options?.video" />
</template>

<script lang="ts" setup>
	import type { EmbedAsset } from '~/graphql/embed'
	import type { ImageAsset } from '~/graphql/image'
	import type { VideoAsset } from '~/graphql/video'
	import type { MaybeArray } from '~/types/maybe-array'
	import { isCraftEmbed, isCraftImage, isCraftVideo } from '~/utilities/craft'
	import type { CraftEmbedOptions } from './craft-embed.vue'
	import type { CraftImageOptions } from './craft-image.vue'
	import type { CraftVideoOptions } from './craft-video.vue'
	import type CraftEmbed from './craft-embed.vue'
	import type CraftImage from './craft-image.vue'
	import type CraftVideo from './craft-video.vue'

	const props = defineProps<{
		options?: {
			embed?: CraftEmbedOptions
			image?: CraftImageOptions
			video?: CraftVideoOptions
		}
		value?: MaybeArray<EmbedAsset | ImageAsset | VideoAsset>
	}>()

	const type = ref<'embed' | 'image' | 'video' | 'unknown'>('unknown')
	const embedEl = ref<InstanceType<typeof CraftEmbed>>()
	const imageEl = ref<InstanceType<typeof CraftImage>>()
	const videoEl = ref<InstanceType<typeof CraftVideo>>()

	onMounted(() => {
		if (isCraftEmbed(props.value)) type.value = 'embed'
		if (isCraftImage(props.value)) type.value = 'image'
		if (isCraftVideo(props.value)) type.value = 'video'
	})

	function play() {
		embedEl.value?.play()
		videoEl.value?.play()
	}

	function pause() {
		embedEl.value?.pause()
		videoEl.value?.pause()
	}

	function toggle() {
		embedEl.value?.toggle()
		videoEl.value?.toggle()
	}

	function seekTo(time: number) {
		embedEl.value?.seekTo(time)
		videoEl.value?.seekTo(time)
	}

	defineExpose({
		type,
		play,
		pause,
		seekTo,
		toggle,
	})
</script>

<style lang="scss"></style>

<template>
	<v-youtube-embed
		v-if="embedData.provider === 'youtube'"
		ref="embedEl"
		:alt="embedData.title"
		:height="embedData.height"
		:src="embedData.src"
		:width="embedData.width" />
</template>

<script lang="ts" setup>
	import { isArray } from 'lodash-es'
	import type { EmbedAsset } from '@/graphql/embed'
	import type { MaybeArray } from '@/types/maybe-array'
	import YouTubeEmbed, {
		type YouTubeEmbedOptions,
	} from '~/components/common/youtube-embed.vue'

	const props = defineProps<{
		options?: CraftEmbedOptions
		value: MaybeArray<EmbedAsset>
	}>()

	const embedEl = ref<InstanceType<typeof YouTubeEmbed>>()

	const embed = computed(() =>
		isArray(props.value) ? props.value[0] : props.value,
	)

	const embedData = computed(() => {
		const { alt, oEmbed, title } = embed.value ?? {}
		const { height, src, providerName, width } = oEmbed ?? {}

		return {
			title,
			description: alt,
			src: getCleanURL(src),
			provider: providerName?.toLowerCase() ?? 'unknown',
			width: width && height ? width : 1920,
			height: width && height ? height : 1080,
		}
	})

	function getCleanURL(url?: string) {
		if (!url) return ''

		const src = new URL(url)
		src.search = ''
		src.hash = ''

		return src.toString()
	}

	defineExpose({
		play: () => embedEl.value?.play(),
		pause: () => embedEl.value?.pause(),
		seekTo: (time: number) => embedEl.value?.seekTo(time),
		toggle: () => embedEl.value?.toggle(),
	})
</script>

<script lang="ts">
	export type CraftEmbedOptions = Omit<
		YouTubeEmbedOptions,
		'alt' | 'height' | 'provider' | 'src' | 'width'
	>
</script>

<style lang="scss"></style>
